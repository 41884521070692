import React from "react"
import { Link } from "gatsby"
import "../css/style.css"
import fb from "../images/fb.png"
import ins from "../images/ins.png"
import bebidas from "../images/bebidas.png"
import alimentos from "../images/food.png"
import mariscos from "../images/marisco.png"

export default function Navbar() {
  return (
    <section className="navbar-area-down">
      <div className="grid-container-navbar">
        <Link
          className="link-scl"
          to="https://www.facebook.com/Cotorritos-Canc%C3%BAn-101460131946969"
          target="_blank"
        >
          <img className="menu-scl-nav" alt="" src={fb} />
        </Link>
        <div className="grid-container-navbar-sec">
          <a
            className="link-button"
            href="#https://media.graphassets.com/output=format:jpg/9N7kMDKSRxewBcvvNkCi"
          >
            <img className="navbar-icon" alt="" src={bebidas} />
          </a>
          <a
            className="link-button"
            href="#https://media.graphassets.com/output=format:jpg/oFKeZZe3TSyUM3D1iHwJ"
          >
            <img className="navbar-icon" alt="" src={alimentos} />
          </a>
          <a className="link-button" href="#mariscos">
            <img className="navbar-icon" alt="" src={mariscos} />
          </a>
        </div>
        <Link
          className="link-scl"
          to="https://www.instagram.com/cotorritoscancun/"
          target="_blank"
        >
          <img className="menu-scl-nav" alt="" src={ins} />
        </Link>
      </div>
    </section>
  )
}
