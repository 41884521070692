import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../css/style.css"

export default function Alimentos() {
  const data = useStaticQuery(graphql`
    query Alimentos {
      gcms {
        menus {
          carta {
            url(transformation: { document: { output: { format: jpg } } })
          }
        }
        mariscoses {
          carta {
            url(transformation: { document: { output: { format: jpg } } })
          }
        }
      }
    }
  `)

  let menu = data.gcms.menus
  let mariscos = data.gcms.mariscoses

  return (
    <section>
      <div className="drink-area-alimentos">
        <div>
          {menu.map(menu => (
            <div className="drink-relative">
              <div className="drink-area-id" id={menu.carta.url} />
              <img
                src={menu.carta.url}
                alt=""
                className="drink-area-alimentos-2"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="drink-area-alimentos">
        <div>
          {mariscos.map(mariscos => (
            <div className="drink-relative">
              <div className="drink-area-id" id="mariscos" />
              <img
                className="drink-area-alimentos-2"
                alt=""
                src={mariscos.carta.url}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
