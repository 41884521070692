import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import "../css/style.css"

export default function Popup() {
  const [showPopup, setShowPopup] = useState(true)

  const SetShow = () => {
    setShowPopup(!showPopup)
  }

  return (
    <section className={showPopup ? "popup1-modal" : "popup1-modal-hide"}>
      <div className="popup1-content">
        <button onClick={() => SetShow()} className="popup1-close">
          {" "}
        </button>
        <a
          href="#https://media.graphassets.com/output=format:jpg/OrD0eeQT1WZsNycz2FYX"
          onClick={() => SetShow()}
        >
          <StaticImage
            src="../images/popupcnc.jpg"
            alt=""
            className="popup1-image"
          />
        </a>
      </div>
      <div onClick={() => SetShow()} className="popup1-modal-button"></div>
    </section>
  )
}
